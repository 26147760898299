import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'

//Components
import Layout from "../components/layout"
import Service from "../components/service"

const ServicePage = () => (
  <Layout>
    <Service/>
  </Layout>
)

export default ServicePage