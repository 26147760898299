import React from "react"
import { Link } from "gatsby"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SectionHeader = (props) => {

    const titel = props.titel
    const description = props.description

   
    const showBackButton = props.path
   
    const textAlign = {
        textAlign: props.textAlign || "center"
    }

    const backButtonPath = props.path
    const buttonContainer = {
        position: "absolute",
        marginTop:"5px"
    }

    return (
        <>
            <div className="section-header">
                { showBackButton ?
                    <Link to={backButtonPath} style={buttonContainer}> 
                         <FontAwesomeIcon icon={faArrowLeft} style={{color: "#E5549B", fontSize: "24px"}}></FontAwesomeIcon> 
                    </Link>     
                    : null
                }
                <h2 className="text-center titel">{titel}</h2>
                <hr className="section"/>
                <div class="section-description" dangerouslySetInnerHTML={{__html: description}}></div>
            </div>
        </>
    )
}

export default SectionHeader

